import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card-header border-0" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body pt-2" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = {
  href: "#",
  class: "text-gray-800 text-hover-primary fw-bolder fs-6"
}
const _hoisted_8 = { class: "text-muted fw-bold d-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown2 = _resolveComponent("Dropdown2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title fw-bolder text-dark" }, "Todo", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown2)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{ 'mb-8': _ctx.list.length - 1 !== index }, "d-flex align-items-center"])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["bullet bullet-vertical h-40px", `bg-${item.color}`])
          }, null, 2),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid mx-5" }, [
            _createElementVNode("input", {
              class: "form-check-input",
              type: "checkbox",
              value: ""
            })
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, _toDisplayString(item.title), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(item.text), 1)
          ]),
          _createElementVNode("span", {
            class: _normalizeClass(["badge fs-8 fw-bolder", `badge-light-${item.color}`])
          }, "New", 2)
        ], 2))
      }), 128))
    ])
  ], 2))
}