import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body pt-5" }
const _hoisted_6 = { class: "symbol symbol-50px me-5" }
const _hoisted_7 = { class: "d-flex flex-column" }
const _hoisted_8 = {
  href: "#",
  class: "text-dark text-hover-primary fs-6 fw-bolder"
}
const _hoisted_9 = { class: "text-muted fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown1 = _resolveComponent("Dropdown1")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder text-dark" }, "Tasks Overview"),
        _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Pending 10 tasks")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{ 'mb-7': _ctx.items.length - 1 !== index }, "d-flex align-items-center"])
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", {
              class: _normalizeClass([`bg-light-${item.color}`, "symbol-label"])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass([`svg-icon-${item.color}`, "svg-icon svg-icon-2x"])
              }, [
                _createVNode(_component_inline_svg, {
                  src: item.icon
                }, null, 8, ["src"])
              ], 2)
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("a", _hoisted_8, _toDisplayString(item.title), 1),
            _createElementVNode("span", _hoisted_9, _toDisplayString(item.description), 1)
          ])
        ], 2))
      }), 128))
    ])
  ], 2))
}